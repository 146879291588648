/*------------------------------------*\
  #LAYOUT__GENERAL
\*------------------------------------*/


// html,
.main-container {
	position: relative !important;
	

	&:not(.pos-fix) {
		overflow-x: hidden !important;
	}
}

html.menu-is-open {
	overflow-x: hidden !important;
	overflow-y: hidden !important;

	.main-container {
		overflow-x: hidden !important;
		overflow-y: hidden !important;
	}

}

.section {
	@include v-space(128);

	// @include grid(md) {
	// 	@include v-space(80);
	// }
}

.section--case-study {

}

.dash-spacer {
	width: 2px;
	display: inline-block;
}

.inline-link {
	color: color(grey, 600);
	display: inline-block;
	position: relative;


	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 10px;
		left: 0;
		right: 0;
		bottom: 2px;
		background: color(primary, 200);
		z-index: -1;
		transition: all .16s cubic-bezier(0.2,1,0.56,1);
	}

	&:hover {

		&::after {
			height: 1.5em;
		}

	}

}


// |=====================================
// | Sidebar                   
// |=====================================

.sidebar {
	position: relative;
    height: 2px;
    width: 40px;
    background: color(grey, 600);
    margin-left: -1.5rem;

    @include grid(md) {
    	margin-left: -1rem;
    }

}

.sidebar--hero {

	&::before {
		content: '';
		position: absolute;
		height: 2px;
		width: 50%;
		left: 0;
		top: -6px;
		background: color(grey, 600);
	}
}

.sidebar--menu {
	margin-top: -8px;
	// margin-left: -1.5rem;
}

.sidebar--project {
    // margin-left: 0px;
}


// |=====================================
// | Knockout color-bar                   
// |=====================================
// |
// | Description...

.bg-knockout {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -5;
	// overflow-y: hidden;

	.grid__row,
	.grid__col {
		height: 100%;
	}

}

.color-bar {
	height: calc(100% + 10px);
	transform: translateY(-5px);
	box-shadow: inset 2px 0 8px rgba(0, 0, 0, .15), inset -2px 0 8px rgba(0, 0, 0, .15);
	background-color: #ffffff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%232e2e32' fill-opacity='0.075' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.color-bar--home {
	// red bg
	// background: rgba(255, 0, 36, 0.3);
	// background: linear-gradient(to bottom, rgba(255, 23, 0, 0.3) 0, rgba(255, 0, 36, 0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff1700', endColorstr='#ff0024', GradientType=0 );

	// grey bg
	// background: rgba(216, 216, 222, .3);
	// background: linear-gradient(to bottom, rgba(244, 244, 245, .3) 0, rgba(216, 216, 222, .3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f5', endColorstr='#d8d8de', GradientType=0 );

	@include grid(md) {
		width: 100vw;
    	transform: translate(-100vw,-5px);
	}
}

.color-bar--project {
	width: 100vw;
	transform: translate(-100vw, -5px);
	
	display: none;
}

.menu-container {

	.color-bar {

		@include grid(md) {
			width: 100vw;
    		transform: translate(-100vw,-5px);
		}

	}

}	

// Specific project page knockout bar colors

.color-bar--employee-portal {
	background-color: rgba(0,255,169,0.3);
	// background: rgba(0,218,218,0.3);
	// background: linear-gradient(to bottom, rgba(0,255,169,0.3) 0%, rgba(0,218,218,0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00FFA9', endColorstr='#00DADA', GradientType=0 );
}

.color-bar--2600hz {
	background-color: rgba(255, 94, 0, 0.3);
	// background: rgba(255, 94, 0, 0.3);
	// background: linear-gradient(to bottom,rgba(255, 153, 0, 0.3) 0, rgba(255, 94, 0, 0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9900', endColorstr='#ff5e00', GradientType=0 );
}

.color-bar--spyder {
	background-color: rgba(253,59,59,0.3);
	// background: rgba(253,20,76,0.3);
	// background: linear-gradient(to bottom, rgba(253,59,59,0.3) 0%, rgba(253,20,76,0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FD3B3B', endColorstr='#FF144C', GradientType=0 );
}

.color-bar--cluster {
	background-color: rgba(41,187,252,0.3);
	// background: rgba(0,79,218,0.3);
	// background: linear-gradient(to bottom, rgba(41,187,252,0.3) 0%, rgba(0,79,218,0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29BBFC', endColorstr='#004FDA', GradientType=0 );
}

.color-bar--telicon {
	background-color: rgba(16, 0, 255, 0.3);
	// background: rgba(16, 0, 255, 0.3);
	// background: linear-gradient(to bottom, rgba(143, 53, 241, .3) 0, rgba(16, 0, 255, 0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8F35F1', endColorstr='#1000ff', GradientType=0 );
}

.color-bar--apps {
	background-color: rgba(98, 255, 0, 0.3);
	// background: rgba(0, 255, 90, 0.3);
	// background: linear-gradient(to bottom, rgba(98, 255, 0, 0.3) 0, rgba(0, 255, 90, 0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62ff00', endColorstr='#00ff5a', GradientType=0 );
}

.color-bar--whitelabeling {
	background-color: rgba(255,212,0,.3);
	// background: rgba(255, 153, 0, 0.3);
	// background: linear-gradient(to bottom, rgba(255,212,0,.3) 0, rgba(255,153,0,.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd400', endColorstr='#ff9900', GradientType=0 );
}

.color-bar--civilmaps {
	background-color: rgba(255, 153, 0, 0.3);
	// background: rgba(255, 94, 0, 0.3);
	// background: linear-gradient(to bottom,rgba(255, 153, 0, 0.3) 0, rgba(255, 94, 0, 0.3) 100%);
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9900', endColorstr='#ff5e00', GradientType=0 );
}


.titled-list {

	&:not(:first-of-type) {
		margin-top: 4rem;
	}
}

.titled-list--stacked {

	@include grid(md) {
		margin-top: 4rem;
	}
}

.titled-list__label {
	@include text-style(sm-caps);
	margin-bottom: .75rem;
}

.titled-list__content {
	@include text-style(sm);

	.list-item {
		line-height: $type__lh--open;
		margin-bottom: .5rem;
	}

}

.icon {
	width: 16px;
	height: 16px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 12px;
	background: color(grey, 600);
	transition: all .16s cubic-bezier(0.2,1,0.56,1);
}

.icon--list {
	width: 12px;
	height: 2px;
	

	&::before,
	&::after {
		position: absolute;
		content: "";
		height: 2px;
		background: inherit;
		width: 16px;
		left: 0;
		transition: top .16s cubic-bezier(0.2,1,0.56,1), bottom .16s cubic-bezier(0.2,1,0.56,1);
	} 

	&::before {
		top: -4px;
		
	} 

	&::after {
		bottom: -4px;
	} 

}

.icon--close {
	width: 14px;
	height: 2px;
	transform: rotate(-45deg);
	margin-right: 8px;
	// display: inline-block;

	&::before {
		position: absolute;
		content: "";
		height: 2px;
		width: 14px;
		transform: rotate(90deg);
		background: inherit;
	}

}

.list__item {
	line-height: $type__lh--open;
    margin-bottom: .5rem;

    .list__link {
    	display: block;
	}
}

