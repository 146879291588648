/*------------------------------------*\
  #PAGES__INDEX
\*------------------------------------*/

// This file contains specific styles for the home page [./index.html]

// Contents:
// ---------
// 1. ***




// |=====================================
// | 1. Section Head                  
// |=====================================
// |
// | Description...




.home {

	.section--intro {

		.section--intro__glyph {
			width: 14px;
			height: 14px;
		}

		.section--intro__content {
			@include v-space(128);
			@include text-style(callout);
			padding-right: .5rem;
		}

	}

	.titled-list {
		.titled-list__content {
			@include text-style(sm);
			color: color(primary, base);
		}
	}

}

.section--showcase {
	overflow-x: hidden;

	// .grid__row {

	// 	.grid__row {
	// 		width: 100%;
	// 		margin-left: 0px;
	// 		margin-right: 0px;
	// 	}

	// }

	.section__title {
		// font-size: $type__fs--x-big;
		// font-weight: $type__fw--black;
  		// font-family: $type__ff--serif;

  		@include text-style(sm-caps);

	}

	.nav-link {
		@include v-space(16);
	}
}

.showcase-list {
	@include v-space(128);
	width: 100%;
	padding-bottom: 8rem;

	.showcase-list__project {
		width: 100%;
		margin-bottom: 2rem;
		// display: flex;
		// align-items: baseline;

		// a {
		// 	width: 100%;
		// 	@include flex-container;
		// 	align-items: baseline;
		// }

		&:last-of-type {
			margin-bottom: 0px;
		}

		// .sidebar {
		// 	opacity: 0;
		// 	width: 0;
		// 	background: color(primary, base);
		// 	transition: all .48s cubic-bezier(0.2,1,0.56,1);

		// 	&.is-visible {
		// 		opacity: 1;
		// 		width: 40px;
		// 	}
		// }
	}

	.project__link {
		width: 100%;
    	margin-left: 0;
    	margin-right: 0;
    	cursor: pointer;

		// @include flex-container;
		// align-items: baseline;

		&:hover {

			.project__count {
				visibility: hidden;
				opacity: 0;
			}

			.project__cta-text {
				visibility: visible;
				opacity: 1;

				&::after {
					transform: translateX(10px);
					opacity: 1;
					color: color(primary, base);
				}
			}

			.project__desc::after {
				width: calc(100% + 1.5rem);

				@include grid(md) {
					width: calc(100% + 1rem);
				}

			}

		}

	}

	.project__label {

	}

	.project__count {
		display: inline-block;
		transition: all .24s cubic-bezier(0.2,1,0.56,1);
		font-size: $type__fs--sm;

		.project__number {
			font-weight: $type__fw--bold;
		}

		.dash-spacer {
			width: 4px;
			display: inline-block;
		}

	}

	.project__cta-text {
		@include text-style(sm-caps);
		color: color(primary, base);
		display: inline-block;
	    transform: translateX(-60px);
	    visibility: hidden;
	    opacity: 0;
	    transition: all .24s cubic-bezier(0.2,1,0.56,1);

	    &::after {
			content: '—';
			position: absolute;
			right: 0px;
			transform: translateX(-4px);
			opacity: 0;
			transition: all .24s cubic-bezier(0.2,1,0.56,1) .08s;
		}
			
	}

	.project__name {
		font-family: 'sf_monosemibold', $type__ff--mono, monospaced;
		font-size: $type__fs--xl;
		font-weight: $type__fw--semi;
	}

	.project__desc {
		@include v-space(16);
		padding-bottom: 2rem;
		position: relative;

		&::after,
		&::before {
			content: "";
			height: 1px;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		&::after {
			width: 0;
			height: 2px;
			background: color(primary, base);
			transition: width .32s cubic-bezier(0.2,1,0.56,1);
		}

		&::before {
			width: calc(100% + 1.5rem);
			border-bottom: 1px solid color(grey, 300);

			@include grid(md) {
				width: calc(100% + 1rem);
			}

		}

	}

}





