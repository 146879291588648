/*------------------------------------*\
  #UTILITIES__OVERRIDE-ANIMATE
\*------------------------------------*/


// animate.css overrides specifically for menu interactions
.menu-container.animated {
	animation-duration: .35s;
	animation-fill-mode: both;
  // transition: transform .5s cubic-bezier(0.2,1,0.56,1);
}

// @keyframes fadeInLeft {
//   // from {
//   //   opacity: 0;
//   //   transform: translate3d(-100%, 0, 0);
//   // }

//   from {
//     opacity: 0;
//     transform: translate3d(-5rem, 0, 0);
//   }

//   to {
//     opacity: 1;
//     transform: none;
//   }
// }

// .fadeInLeft {
//   animation-name: fadeInLeft;
// }

// @keyframes fadeOutLeft {
//   from {
//     opacity: 1;
//   }

//   to {
//     opacity: 0;
//     transform: translate3d(-5rem, 0, 0);
//   }
// }

// .fadeOutLeft {
//   animation-name: fadeOutLeft;
// }

