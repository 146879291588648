/*------------------------------------*\
  #COMPONENTS/ROWS
\*------------------------------------*/


.grid__row {

	@include grid(sm) {
		&.sm\:row-reverse {
			flex-direction: column-reverse;
			flex-wrap: wrap;
		}
	}

	@include grid(md) {
		flex-direction: row;
		flex-wrap: wrap;

		&.md\:row-reverse {
			flex-direction: row-reverse;
		}
	}

	@include grid(lg) {
		flex-direction: row;
		flex-wrap: wrap;

		&.lg\:row-reverse {
			flex-direction: row-reverse;
		}
	}

}
