/*------------------------------------*\
  #PAGES__PROJECT
\*------------------------------------*/

// This file contains specific styles for the general look and feel of the project pages

// Contents:
// ---------
// 1. ***




// |=====================================
// | 1. Section Head                  
// |=====================================
// |
// | Description...


.project {

	img {
		width: 100%;
	}

	.section__title {
		@include text-style(sm-caps);	
	}

	.proj__intro {
		@include v-space(64);
	}

}

.intro__list {
    margin-top: 1.5rem;
    line-height: 1.45;
    transform: translateX(1rem);

	li {
		margin-top: 1rem;
		position: relative;

		&::before {
			position: absolute;
	    	content: '—';
	    	transform: translateX(-1rem);
	    	font-family: 'sf_monobold', monospaced;
		}

		// span {
		// 	font-family: 'sf_monobold';
		// }
	}
}

.caption {
	@include text-style(xs);
	font-size: 0.6875rem;
	color: color(grey, 500);
	line-height: 1.5;
    font-weight: 400;

    @include grid(md) {
    	margin-bottom: 1.5rem;
    }

}

.caption--right {
	text-align: left;

	@include grid(lg) {
		text-align: right;
	}

}

@include grid(md) {

	.md\:caption-fix {
		margin-top: 1.5rem;
	}
	
}


.section--proj-credits {
	padding-bottom: 8rem;
}


// Project image specific styles

.has-depth {
	box-shadow: 0px 1px 16px rgba(0,0,0, 0.16);
}
 
.is-overlapping {
	margin-top: 4rem;

	@include grid(lg) {
		margin-top: -20%;
		background: color(white);
	}

}

.is-full-width {
    transform: translateX(-1.5rem);
    max-width: calc(100% + 3rem);
    width: calc(100vw + 3rem) !important;
}

.is-pulled-right {
	max-width: calc(100% + 1.5rem);
    width: calc(100% + 1.5rem);
}

// .is-under--top {
// 	margin-top: 4rem;

// 	@include grid(lg) {
// 		z-index: -1;
// 		margin-top: -4rem;
// 		background: color(white);
// 	}

// }

.list--callouts {

	margin-top: 1.5rem;
    counter-reset: my-awesome-counter;
    padding-left: 48px;
    line-height: 1.625;

	.list-item {
	    counter-increment: my-awesome-counter;
    	position: relative;

    	&:not(:last-of-type) {
    		margin-bottom: 1.5rem;
    	}
	}

	.list-item::before {
		content: counter(my-awesome-counter);
	    border-radius: 100%;
	    color: #fff;
	    display: inline-block;
	    width: 32px;
	    height: 32px;
	    background: #ee2d48;
	    text-align: center;
	    line-height: 32px;
	    position: absolute;
	    left: -48px;
	    top: -4px;
	}

}

.list--bullets {

	margin-top: 1.5rem;
    line-height: 1.625;

	.list-item:not(:last-of-type) {
    	margin-bottom: 1.5rem;	
	}

}


// scrolling test for mui app icons

// .scroller {
//  	background: color(grey, 700);
//  	// height: 600px;
//  	padding: 3rem 0;
//  	overflow: hidden;
// }

// .scroller__content {
// 	background: url('../img/Apps_02-Grid.png') repeat-x;
// 	height: 632px;
// 	width: 7560px;
// 	animation: slide 60s linear infinite;
// }

// @keyframes slide{
//   0%{
//     transform: translate3d(0, 0, 0);
//   }
//   100%{
//     transform: translate3d(-2520px, 0, 0);
//   }
// }