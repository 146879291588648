/*------------------------------------*\
  #LAYOUT__MOBILE
\*------------------------------------*/

.sm-container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    background: #ffffff;

    top: 0;
    left: 0;
}

.sm-disclaimer {
	height: 100%;
	overflow-y: scroll;

	.grid {
		height: 100%;
		overflow-x: hidden;
		// padding-left: 0rem;
  		// padding-right: 0rem;
	}

	.grid__col {
		padding-left: 0rem;
		padding-right: 0rem;
	}


	.disclaimer__logo {
		margin-top: 2.5rem;
		padding-left: 1rem;
	}

	.disclaimer__header {
	    font-family: 'Playfair Display', serif;
	    font-size: 2.75rem;
	    font-weight: 900;
	    text-align: right;
	    position: relative;
	    margin-top: 2rem;
	    margin-right: 1rem;

	    &::before {
	    	content: "";
	    	position: absolute;
	    	width: 20px;
	    	height: 2px;
	    	background: color(grey, 600);
	    	left: 0;
	    	bottom: 0;
	    }

	    &::after {
	    	content: "🤔";
	    	position: absolute;
	    	right: 0;
	    	bottom: -60px;
	    	z-index: 1;

	    	text-shadow: 0 2px 8px rgba(0,0,0,.24);
	    }
	}

	.color-bg {
		margin-top: 42px;
	    width: calc(100vw + 10px);
	    transform: translateX(-5px);
	    transform-origin: center;
	    box-shadow: inset 0 2px 8px rgba(0,0,0,.1), inset 0 -1px 8px rgba(0,0,0,.1);
	    // background: rgba(216,216,222,.3);
	    // background: linear-gradient(to bottom,rgba(244,244,245,.3) 0,rgba(216,216,222,.3) 100%);
	    background-color: #ffffff;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%232e2e32' fill-opacity='0.075' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
	}

	.disclaimer__sub-header {
		// width: calc(100% + 10px);
		// transform: translateX(-5px);
		font-size: 1rem;
		padding: 1.5rem 1rem;
		width: 87%;
		line-height: $type__lh--open;
		transform: translateX(5px);
		// margin-top: 40px;
  		// padding: 1rem 3rem 1rem 1rem;
		// box-shadow: inset 0px 2px 8px rgba(0, 0, 0, .15), inset 0px -2px 8px rgba(0, 0, 0, .15);
		// background: rgba(216, 216, 222, .3);
		// background: linear-gradient(to bottom, rgba(244, 244, 245, .3) 0, rgba(216, 216, 222, .3) 100%);
		// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f5', endColorstr='#d8d8de', GradientType=0 );	
	}

	.disclaimer__body {
		margin-top: 1rem;
		font-size: .875rem;
		// font-family: 'Work Sans';
		line-height: $type__lh--open;
		padding-left: 1rem;
    	padding-right: 1rem;
	}

	.disclaimer__actions {
		margin-top: 2rem;
		padding-left: 1rem;
    	padding-right: 1rem;
	}

	.disclaimer__cta {
		width: 100%;
	    display: block;
	    border: 1px solid #2e2e32;
	    text-align: center;
	    padding: 1rem;
	    margin-top: .5rem;
	}

	.disclaimer__footer {
		text-align: center;
		margin-top: 2.5rem;
		font-size: 1rem;
		padding-bottom: 2rem;

		.footer__contact {

		}

		.copyright__date {
			margin-top: .75rem;
		}

	}

}

