/*------------------------------------*\
  #BASE__VERT-RHYTHM
\*------------------------------------*/

// |-------------------------------------
// | Spacing                   
// |-------------------------------------
// |
// | 8px base spacing assuming base typography is set at 1rem = 16px

$spc-8: 0.5rem; 					// 8px @ base-16

$spc-4: calc( #{$spc-8} / 2 );		// 4px @ base-16
$spc-16: calc( #{$spc-8} * 2 ); 	// 16px @ base-16
$spc-24: calc( #{$spc-8} * 3 ); 	// 24px @ base-16
$spc-32: calc( #{$spc-8} * 4 );		// 32px @ base-16
$spc-40: calc( #{$spc-8} * 5 );		// 40px @ base-16
$spc-48: calc( #{$spc-8} * 6 );		// 48px @ base-16
$spc-56: calc( #{$spc-8} * 7 );		// 56px @ base-16
$spc-64: calc( #{$spc-8} * 8 );		// 64px @ base-16
$spc-72: calc( #{$spc-8} * 9 );		// 72px @ base-16

$spc-80: calc( #{$spc-8} * 10 );	// 80px @ base-16
$spc-96: calc( #{$spc-8} * 12 );    // 96px @ base-16
$spc-128: calc( #{$spc-8} * 16 );	// 128px @ base-16




// |=========================================
// | 1. Mixin for consistent vertical spacing                   
// |=========================================
// |
// | 8px base vertical spacing


@mixin v-space($val) {

    @if $val == 4 {
        margin-top: $spc-05;
    }

    @else if $val == 8 {
        margin-top: $spc-8;
    }

    @else if $val == 16 {
        margin-top: $spc-16;
    }

    @else if $val == 24 {
        margin-top: $spc-24;
    }

    @else if $val == 32 {
        margin-top: $spc-32;
    }

    @else if $val == 40 {
        margin-top: $spc-40;
    }

    @else if $val == 48 {
        margin-top: $spc-48;
    }

    @else if $val == 56 {
        margin-top: $spc-56;
    }

    @else if $val == 64 {
        margin-top: $spc-64;
    }

    @else if $val == 72 {
        margin-top: $spc-72;
    }

    @else if $val == 80 {
        margin-top: $spc-80;
    }

    @else if $val == 96 {
        margin-top: $spc-96;
    }

    @else if $val == 128 {
        margin-top: $spc-128;
    }

    @else {
        @error "Invalid v-space input: `#{$val}` is not a valid v-space";
    }
}



// |=========================================
// | 2. Utilitiy Classes for vert spacing                   
// |=========================================


.u_mt--lg {
    @include v-space(64);
}

.u_mt--xl {
    @include v-space(128);
}





// @mixin v-space($val) {

//     @if $val == xs {
//         margin-top: $spc-05;
//     }

//     @else if $val == sm {
//         margin-top: $spc-sm;
//     }

//     @else if $val == md {
//         margin-top: $spc-md;
//     }

//     @else if $val == lg {
//         margin-top: $spc-lg;
//     }

//     @else {
//         @error "Invalid v-space input: `#{$val}` is not a valid v-space";
//     }
// }

// .u-mt-05 { margin-top: $spc-05; }
// .u-mt-1 { margin-top: $spc-1; }
// .u-mt-2 { margin-top: $spc-2; }
// .u-mt-3 { margin-top: $spc-3; }
// .u-mt-4 { margin-top: $spc-4; }
// .u-mt-5 { margin-top: $spc-5; }
// .u-mt-6 { margin-top: $spc-6; }
// .u-mt-7 { margin-top: $spc-7; }
// .u-mt-8 { margin-top: $spc-8; }

// .u-mr-05 { margin-right: $spc-05; }
// .u-mr-1 { margin-right: $spc-1; }
// .u-mr-2 { margin-right: $spc-2; }
// .u-mr-3 { margin-right: $spc-3; }
// .u-mr-4 { margin-right: $spc-4; }
// .u-mr-5 { margin-right: $spc-5; }
// .u-mr-6 { margin-right: $spc-6; }
// .u-mr-7 { margin-right: $spc-7; }
// .u-mr-8 { margin-right: $spc-8; }

// .u-mb-05 { margin-bottom: $spc-05; }
// .u-mb-1 { margin-bottom: $spc-1; }
// .u-mb-2 { margin-bottom: $spc-2; }
// .u-mb-3 { margin-bottom: $spc-3; }
// .u-mb-4 { margin-bottom: $spc-4; }
// .u-mb-5 { margin-bottom: $spc-5; }
// .u-mb-6 { margin-bottom: $spc-6; }
// .u-mb-7 { margin-bottom: $spc-7; }
// .u-mb-8 { margin-bottom: $spc-8; }

// .u-ml-05 { margin-left: $spc-05; }
// .u-ml-1 { margin-left: $spc-1; }
// .u-ml-2 { margin-left: $spc-2; }
// .u-ml-3 { margin-left: $spc-3; }
// .u-ml-4 { margin-left: $spc-4; }
// .u-ml-5 { margin-left: $spc-5; }
// .u-ml-6 { margin-left: $spc-6; }
// .u-ml-7 { margin-left: $spc-7; }
// .u-ml-8 { margin-left: $spc-8; }



