/*------------------------------------*\
  #LAYOUT__FOOTER
\*------------------------------------*/


.footer {
	@include text-style(xs);
	padding-bottom: 1rem;
}

.footer__contact {
	color: color(primary, base);
}

.footer__copyright {
	line-height: $type__lh--open;

	.copyright__date {

	}

	.copyright__font-fix {
		transform: scale(1.5) translateY(2px);
    	display: inline-block;
   		width: 12px;
	}
	.copyright__disclaimer {
		text-transform: uppercase;
	}

}











