/*------------------------------------*\
  #LAYOUT__HERO
\*------------------------------------*/


.hero {
	height: 100%;
	height: 100vh;
	max-height: 1000px;
	display: flex;
	flex-direction: column;

	padding-bottom: 1.5rem;
    justify-content: space-between;

    position: relative;
}

.hero--proj {
	padding-bottom: 0px;
	height: 85vh;

	.hero__wrap--logo {
    	margin-bottom: auto;
	}

	.hero__wrap--title {
	}

	// controls the animated blobs on each project page

	.scene {
		position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: -50%;
	    width: 50vw;
    	height: 50vh;

	    margin: auto 0;
	    pointer-events: none;
	    z-index: -1;
		// filter: blur(10px);

	    @include grid(md) {
	    	margin: auto;
	    }

	    path {
	    	
	    }

	}

	.hero__wrap--nav {
		@include v-space(96);
		margin-bottom: auto;
	}

	.project__count {
		font-size: $type__fs--sm;
	    position: absolute;
	    right: 6rem;
	    bottom: -2rem;
	    transform: rotate(-90deg);

		.project__number {
			font-weight: $type__fw--bold;
		}

		.dash-spacer {
			width: 4px;
			display: inline-block;
		}

	}

}

.hero__wrap {
	width: 100%;
}

.hero__wrap--logo {
	margin-top: 4rem;
}

.hero__logo-link {
	width: 42px;
	height: 50px;
	display: inline-block;
}

.hero__logo {

}

.hero__wrap--title {
	
}

.hero__title {
	@include text-style(hero--sm);
	color: color(grey, 600);
	position: relative;

	@include grid(lg) {
		@include text-style(hero);
	}

	.accent {
		font-size: $type__fs--xxl;
		font-style: italic;
		font-weight: $type__fw--base;
	}

}

.hero__wrap--nav { }

.hero__nav { }

.nav-link--menu { }

.nav-link { }

.nav-link {
	@include text-style(sm-caps);
	line-height: $type__lh--open;
	color: color(grey, 600);
	transition: all .16s cubic-bezier(0.2,1,0.56,1);
	padding: .75rem .75rem .75rem 0;
	display: inline-block;

	&:hover {
		color: color(primary, base);

		.icon {
			background: color(primary, base);
		}

		.icon--list {
			width: 16px;
			margin-right: 8px;

			&::before {
				top: -6px;
			} 

			&::after {
				bottom: -6px;
			} 
		}

		.icon--close {
			transform: rotate(-45deg) scale(1.4);
			
		}
	}

}

.hero__wrap--sub { }

.hero__social-item {
	width: 18px;
	height: 18px;
	fill: color(grey, 600);
	display: inline-block;
	margin-right: .5rem;
}

.hero .titled-list__label {
	color: color(grey, 600);
	margin-bottom: 0;

	span {
		color: color(primary, base);
	}
}

.hero__date {
	@include text-style(sm);
}

.hero__proj-summary {
	@include text-style(callout);
}

.hero__proj-glyph {
	font-family: 'sf_monomedium';
	margin-top: -3px;
}




// OVERRIDES

@include grid(md) {

	.md\:title-fix {
		display: inline-block;

		.scene {
			left: 30%;
		}
		
	}
		
}

