/*------------------------------------*\
  #LAYOUT__MENU
\*------------------------------------*/


.menu-container {
	position: fixed;
	display: none;
	width: 100%;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: color(white);
	pointer-events: none;
}

.menu-container.is-open {
	display: block;
	pointer-events: auto;
}

.menu {
	height: 100%;

	.grid {
		height: 100%;
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
	}

}
 
// .menu .sidebar {
// 	margin-top: -8px;
// 	margin-left: -1.5rem;
// }

.menu__header {
	// @include v-space(64);

	.header__text {
		@include text-style(hero);
	}

}



.menu__body {
	@include v-space(72);
}

.menu__spacer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.menu__links-list {
	margin-top: 1.625rem;

	.links-list__item {

		// &:hover,
		// &.is-active {

		// 	.links-list__link {
		// 		color: color(primary, base);
		// 	}
		// }

	}

	.links-list__link {
		display: inline-block;
		padding: .5rem .5rem .5rem 0;

		&:hover,
		&.is-active {
			color: color(primary, base);
		}
	}

}

.menu__toggle {

	.nav-link {
		color: color(primary, base);

		.icon {
			background: color(primary, base);
		}

	}
}

.menu__social {
	transform: translateY(-8px);

	.social__item {
		display: inline-block;
		vertical-align: middle;
	}

}

.menu__proj-list {

	.proj-list__label {
		@include text-style(sm-caps);
		margin-bottom: 1rem;
	}

	.proj-list__proj {
		// border-bottom: 1px solid color(grey, 200);

		// &.is-current,
		&:hover {

			.proj__number {

				&::after {
					transform: translateX(12px);
					opacity: 1;
					color: color(primary, base);
				}

			}

			.proj__name {
				color: color(primary, base);
			}
		}
	}

	.proj-list--disclaimer {
		// text-decoration: line-through;
		pointer-events: none;

		.proj__name {
			display: inline-block;
			vertical-align: top;
		}

		.proj__number {
			transform: translateY(3px);
		}

		.strike {
			text-decoration: line-through;
			color: color(grey, 300);
			display: block;
		}

		.tag {
		    // text-transform: uppercase;
    		// font-size: 0.75rem;
    		@include text-style(sm-caps);
    		color: color(grey, base);
    		display: block;
		}

	}

	.proj__link {
		display: block;
	}

	.proj__number {
		position: relative;

		&::after {
			content: '—';
			position: absolute;
			right: 0px;
			transform: translateX(-4px);
			// top: 10%;
			opacity: 0;
			transition: all .16s cubic-bezier(0.2,1,0.56,1);
		}

	}

	.proj__name {
		@include text-style(callout);
	}

}



.proj__link {
	padding: .5rem .5rem .5rem 0;
}

.proj__number {
	display: inline-block;
	@include text-style(sm);
    margin-right: 4rem;
    font-family: 'sf_monosemibold','Source Code Pro', monospace;
}
